<template>
  <div id="wrapper">
    <div class="container-fluid not-found">
      <div class="row">
        <div class="col text-center">
          <img class="pj-panda-working img-fluid"
               src="../assets/images/404_img.png"
               alt="pj-panda-working"/>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <h2>IS IT TOO LATE TO SAY SORRY?</h2>
          <p class="text-white small">Unfortunately, this page does not exist.
            Click the button below to go back to our site.</p>
          <b-button class="btn btn-h3-small"
                    @click="goBack"
                    variant="outline-primary">
            Back
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name    : 'NotFound',
    methods : {

      /**
       * Go back to previous page or home page
       */
      goBack() {
        if (window.history.length > 2)
          this.$router.go(-1);
        else
          this.$router.push('/');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../assets/scss/components/not-found';
</style>

